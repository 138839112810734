import { DontHydrate, Link, Responsive } from '@loveholidays/design-system';
import React from 'react';

import { DestinationSummaryComponent as DestinationSummaryComponentType } from '@AuroraTypes';
import { ExpandableFadeContent } from '@UX/ExpandableFadeContent/ExpandableFadeContent';
import { hastToReact } from '@UX/Hast/Hast';

export const DestinationSummaryComponent: React.FC<
  Pick<DestinationSummaryComponentType, 'destinationSummary'>
> = ({ destinationSummary }) => (
  <ExpandableFadeContent
    trackingContext="destination-summary"
    characterCount={destinationSummary.characterCount}
    shownHeight={destinationSummary.shownHeight as Responsive<number>}
    containerStyles={{ paddingBottom: 'xl' }}
  >
    <DontHydrate>
      {hastToReact(destinationSummary.content, {
        h2: {
          Component: 'h2',
          Props: {
            sx: {
              fontWeight: 'normal',
              fontSize: 'xxl',
            },
          },
        },
        a: {
          Component: Link,
        },
      })}
    </DontHydrate>
  </ExpandableFadeContent>
);
